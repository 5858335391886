import "@popperjs/core";
import "bootstrap";
// core version + navigation, pagination modules:
import Swiper, {Navigation, Pagination} from 'swiper';

Swiper.use([Navigation, Pagination]);


document.addEventListener("DOMContentLoaded", function (event) {

    const swiper = new Swiper('.swiper', {
        // Optional parameters
        direction: 'horizontal',

        slidesPerView: "auto",
        slidesPerGroupAuto: true,
        spaceBetween: 20,

        // If we need pagination
        pagination: {
            el: '.swiper-pagination',
        },

        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },

        // And if we need scrollbar
        scrollbar: {
            el: '.swiper-scrollbar',
        },
    });


    let dropdowns = document.querySelectorAll('.dropdown-toggle')
    dropdowns.forEach((dd) => {
        dd.addEventListener('click', function (e) {
            e.stopImmediatePropagation();
            let el = this.nextElementSibling
            el.style.display = el.style.display === 'block' ? 'none' : 'block'
        })
    })
});